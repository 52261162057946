import IntersectionHydrator from '@netc/intersection-hydrator';
import { registrations } from '../registrations';
new IntersectionHydrator(registrations);
function fixImages() {
    const img = document.querySelectorAll('img[data-cfsrc]');
    img.forEach((img) => {
        const cfSrc = img.getAttribute('data-cfsrc');
        if (cfSrc !== null) {
            img.src = cfSrc;
        }
        img.setAttribute('style', '');
    });
}
window.addEventListener('CookiebotOnAccept', fixImages);
window.addEventListener('CookiebotOnDecline', fixImages);
